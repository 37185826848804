import {
  ComboBox,
  DefaultButton,
  DialogContent,
  DialogFooter,
  Dropdown,
  IComboBoxOption,
  IComboBoxStyles,
  IconButton,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
  Stack,
  Text
} from '@fluentui/react'

import { yupResolver } from '@hookform/resolvers/yup'
import { BijlageBron } from 'enums/bijlageBron'
import {
  BijlageKenmerk,
  BijlageKenmerkCertificaatWaardeLabelMap,
  BijlageKenmerkHandleidingWaardeLabelMap
} from 'enums/bijlageKenmerk'
import { BijlageType, BijlageTypeLabelMap } from 'enums/bijlageType'
import { FileUploadType } from 'enums/fileUploadType'
import { IBijlageUpload, uploadBijlageSchema } from 'interfaces/bijlage'
import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { IFileToUpload } from './IFileToUpload'
import { IFileUploadContext } from './IFileUploadContext'
import { IFileUploadOpdrachtBijlageContext } from './OpdrachtBijlage/IFileUploadOpdrachtBijlageContext'
import { setRegelId } from 'store/actions/opdrachten/regels'
import { useAppDispatch } from 'store'
import { IOpdrachtregel } from 'interfaces/opdracht'

const EnabledFileTypes: string[] = [BijlageType.MAN, BijlageType.STR, BijlageType.MTE]

const comboboxStyles: Partial<IComboBoxStyles> = {
  container: { flexGrow: 1 },
  optionsContainer: { maxHeight: 300 }
}

const kenmerkwaardeRequired = (watchFields: any, file: IFileToUpload) => {
  if (!(watchFields || {}).hasOwnProperty(file.id)) return false
  if (!watchFields[file.id].soort) return false
  return watchFields[file.id].soort !== BijlageType.MTE
}

interface IFormProps {
  files: IFileToUpload[]
  disabled: boolean
  loading: boolean
  context: IFileUploadContext | IFileUploadOpdrachtBijlageContext
  onDeleteFile: (id: string) => void
  onSave: (args: Record<string, IBijlageUpload>) => void
  regels: IOpdrachtregel[];
  disableImageUpload?: boolean
}

const Form: React.FC<IFormProps> = ({
                                      files,
                                      disabled,
                                      loading,
                                      context,
                                      onSave,
                                      onDeleteFile,
                                      regels,
                                      disableImageUpload
                                    }) => {
  const { handleSubmit, control, formState, setValue, watch, trigger } = useForm<any>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {},
    resolver: (context.type === FileUploadType.Document && yupResolver(uploadBijlageSchema)) || undefined
  })


  const dispatch = useAppDispatch()
  const dropdownItems = useMemo(() => {
    if (context) {
      return regels?.map((regel) => ({
        key: regel.id,
        text: `${regel.regelnummer}. ${regel.omschrijving}`
      }))
    } else {
      return []
    }
  }, [regels])

  const bijlageTypes: IComboBoxOption[] = useMemo(() => {
    return Object.keys(BijlageTypeLabelMap).map(
      key =>
        ({
          key,
          text: BijlageTypeLabelMap[key],
          hidden: !EnabledFileTypes.includes(key)
        } as IComboBoxOption)
    )
  }, [])

  const certificaatKenmerken: IComboBoxOption[] = useMemo(() => {
    return Object.keys(BijlageKenmerkCertificaatWaardeLabelMap).map(
      key =>
        ({
          key,
          text: BijlageKenmerkCertificaatWaardeLabelMap[key]
        } as IComboBoxOption)
    )
  }, [])

  const handleidingKenmerken: IComboBoxOption[] = useMemo(() => {
    return Object.keys(BijlageKenmerkHandleidingWaardeLabelMap).map(
      key =>
        ({
          key,
          text: BijlageKenmerkHandleidingWaardeLabelMap[key]
        } as IComboBoxOption)
    )
  }, [])

  const watchFields = watch()
  const handleDelete = useCallback(
    (id: string) => {
      if ((watchFields || {}).hasOwnProperty(id)) {
        delete watchFields[id]
      }

      onDeleteFile(id)
    },
    [onDeleteFile, watchFields]
  )

  const handleSave = useCallback(() => {
    handleSubmit((data: Record<string, IBijlageUpload>, _) => {
      // The form is not used for images, therefore submit the files as is.
      if (context.type !== FileUploadType.Document) {
        for (const file of files) {
          data[file.id] = {
            bijlageId: '',
            soort: context.bron === BijlageBron.Werkzaamheden ? BijlageType.OTA : BijlageType.PHI
          }
        }
      }

      onSave(data)
    })()
  }, [onSave, handleSubmit, context, files])

  return (
    <>
      <DialogContent styles={{ header: { height: 0, width: 0 }, inner: { padding: 0 } }}>
        <Stack styles={{ root: { marginTop: 10 } }}>
          <form onSubmit={handleSave}>
            {files.map(file => (
              <Stack.Item key={file.id} styles={{ root: { paddingBottom: 15 } }}>
                {disableImageUpload && files?.[0]?.file.type?.startsWith('image/') ? null :
                  <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                    <Stack.Item>
                      <Text variant="mediumPlus" styles={{ root: { fontWeight: 600 } }}>
                        {file.file.name}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <IconButton
                        iconProps={{
                          iconName: 'Delete',
                          onClick: () => handleDelete(file.id)
                        }}
                        disabled={disabled}
                      />
                    </Stack.Item>
                  </Stack>}
                <ProgressIndicator percentComplete={file.uploaded ? 100 : file.uploading ? undefined : 0} />
                {disableImageUpload && files?.[0]?.file.type?.startsWith('image/') ?
                  <Text>Afbeeldingen kunnen toegevoegd worden via tabblad Foto's</Text> :
                  <>
                    {context.showRegelDropdown && (
                      <Stack>
                        <Controller
                          name={`[${file.id}].opdrachtregelId`}
                          control={control}
                          defaultValue={''}
                          rules={{ required: true }}
                          render={({ onBlur, value }) => (
                            <Dropdown
                              required={true}
                              label="Selecteer opdrachtregel"
                              options={dropdownItems}
                              selectedKey={value}
                              onBlur={onBlur}
                              onChange={(_, option, value) => {
                                const newValue = option ? (option.key as number) : value || undefined
                                setValue(`[${file.id}].opdrachtregelId`, newValue)
                                dispatch(setRegelId({ opdrachtregelId: newValue }))
                                onBlur()
                                trigger()
                              }}
                              placeholder="Selecteer opdrachtregel"
                            />
                          )}
                        />
                      </Stack>
                    )}
                    {context.type === FileUploadType.Document && (
                      <>
                        <Stack horizontal horizontalAlign="start" tokens={{ childrenGap: 10 }}>
                          <Controller
                            name={`[${file.id}].soort`}
                            control={control}
                            defaultValue={''}
                            render={({ onBlur, value }) => (
                              <ComboBox
                                styles={comboboxStyles}
                                options={bijlageTypes}
                                allowFreeform={false}
                                autoComplete="off"
                                label="Soort"
                                required
                                selectedKey={value}
                                onBlur={onBlur}
                                onChange={(_, option, value) => {
                                  const newValue = option ? (option.key as string) : value || ''
                                  setValue(`[${file.id}].soort`, newValue)

                                  if (option!.key === BijlageType.STR) {
                                    setValue(`[${file.id}].kenmerk`, BijlageKenmerk.STRCategory)
                                  } else if (option!.key === BijlageType.MAN) {
                                    setValue(`[${file.id}].kenmerk`, BijlageKenmerk.MANCategory)
                                  }

                                  setValue(`[${file.id}].kenmerkwaarde`, '')

                                  trigger(`[${file.id}].kenmerkwaarde`)
                                  onBlur()
                                }}
                              />
                            )}
                          />
                          {/* Field has to be on the form in order to use 'setValue' on it. */}
                          <Controller
                            name={`[${file.id}].kenmerk`}
                            control={control}
                            defaultValue={''}
                            render={() => <></>}
                          />
                          <Controller
                            name={`[${file.id}].kenmerkwaarde`}
                            control={control}
                            defaultValue={''}
                            render={({ onBlur, value }) => (
                              <ComboBox
                                styles={comboboxStyles}
                                options={
                                  ((watchFields || {}).hasOwnProperty(file.id) &&
                                  watchFields[file.id].soort === BijlageType.STR
                                    ? certificaatKenmerken
                                    : handleidingKenmerken) || []
                                }
                                allowFreeform={false}
                                autoComplete="off"
                                label="Kenmerk"
                                required={kenmerkwaardeRequired(watchFields, file)}
                                disabled={!kenmerkwaardeRequired(watchFields, file)}
                                selectedKey={value}
                                onBlur={onBlur}
                                onChange={(_, option, value) => {
                                  const newValue = option ? (option.key as string) : value || ''
                                  setValue(`[${file.id}].kenmerkwaarde`, newValue)
                                  onBlur()
                                  trigger()
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </>
                    )}
                  </>}
              </Stack.Item>
            ))}
            <input type="submit" style={{ visibility: 'hidden' }} />
          </form>
        </Stack>
      </DialogContent>
      <DialogFooter>
        {disableImageUpload && files?.[0]?.file.type?.startsWith('image/') ? null :
          <DefaultButton primary allowDisabledFocus disabled={disabled || !formState.isValid} onClick={handleSave}>
            {loading ? <Spinner size={SpinnerSize.small} /> : 'Uploaden'}
          </DefaultButton>}
      </DialogFooter>
    </>
  )
}

export default Form
